document.addEventListener('DOMContentLoaded', function () {
    const contentContainers = document.querySelectorAll('.sm-content');

    contentContainers.forEach((content, index) => {
        const showMoreButton = document.createElement('div');
        showMoreButton.classList.add('sm-show-more');
        showMoreButton.setAttribute('data-iframe-size', 'data-iframe-size');
        showMoreButton.innerHTML = 'Show more &#9660'; // Use &#9650; for chevron-up
        content.parentNode.insertBefore(showMoreButton, content.nextSibling);

        showMoreButton.addEventListener('click', function () {
            if (content.style.maxHeight) {
                content.style.maxHeight = null;
                showMoreButton.innerHTML = 'Show more &#9660'; // Use &#9650; for chevron-up
            } else {
                content.style.maxHeight = content.scrollHeight + 'px';
                showMoreButton.innerHTML = 'Show less &#9650'; // Use &#9660; for chevron-down
            }
        });
    });
});
